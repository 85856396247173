/**
 *  * Rôle : Erreur 404
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import { Link } from 'gatsby';
import Container from '../components/body/Container';
import Layout from '../components/body/layout';
import Seo from '../components/seo';

const NotFoundPage = () => (
	<Layout title="Erreur 404">
		<Seo title="404 Page Not found" />
		<Container className="flex flex-col items-center justify-center py-40">
			<h2 className="text-8xl">404</h2>
			<p>Désolé cette page n'éxiste pas.</p>
			<Link
				to="/"
				className="bg-secondary hover:bg-primary rounded-sm py-4 px-6 text-white text-xs uppercase flex items-center justify-center animate"
			>
				<svg
					className="h-5 w-5 mr-2"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					strokeWidth="2"
					stroke="currentColor"
					fill="none"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					{' '}
					<path stroke="none" d="M0 0h24v24H0z" />{' '}
					<polyline points="5 12 3 12 12 3 21 12 19 12" />{' '}
					<path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />{' '}
					<path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
				</svg>
				<span>page d'accueil</span>
			</Link>
		</Container>
	</Layout>
);

export default NotFoundPage;
